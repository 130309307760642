import { createChatBotMessage } from 'react-chatbot-kit';
import DogPicture from './DogPicture.jsx'

const botName = 'PSLBot';
const config = {
  initialMessages: [createChatBotMessage(`Hai, Aku ${botName}. Ada yang bisa dibantu?`)],
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: '#376B7E',
    },
    chatButton: {
      backgroundColor: '#5ccc9d',
    },
  },
  widgets: [
    {
      widgetName: 'dogPicture',
      widgetFunc: (props) => <DogPicture {...props} />,
    },
  ],
};

export default config;