import React from 'react';
import "leaflet/dist/leaflet.css"
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import pinBiru from "assets/images/icons/pin-biru.svg";
import pinAbu from "assets/images/icons/pin-abu.svg";

// Fungsi untuk menambahkan ikon pada marker
const customIcon = new L.Icon({
  iconUrl: pinBiru,
//   iconUrl: require('../../../../../src/assets/images/logopsl.png'),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
//   shadowUrl: pinAbu,
//   shadowUrl: require('../../../../../src/assets/images/logopsl01.png'),
  shadowSize: [41, 41],
});

const MapWithMarker = ({location}) => {
  return (
    <MapContainer className='z-0 ' center={[ -6.111678834916795, 106.8754937693104]} zoom={13} style={{ height: '50vh', width: '80vh' }}>
        <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        
        {/* Tambahkan Marker di lokasi yang diinginkan */}
        
        {location?.map((val, i) => {
            return(
            <Marker position={[val.lat, val.lng]} icon={customIcon}>
                <Popup>
                {val.label} "{val.layanan}"
                </Popup>
            </Marker>
            )
        })}
    </MapContainer>
    
  );
};

export default MapWithMarker;