import React, { useEffect, useState } from "react";
import SlideshowCustom from "components/reuseable/Slideshow/SlideshowCustom";
import ApiGet from "api/ApiGet";
import { responseResult } from "utils/ResponseStatus";
import { uAPI } from "api/config";
import ReactSlideshow from "components/reuseable/Slideshow/ReactSlideshow";
const ProfilHome = () => {
    const [state, setState] = useState([])
    const lang = localStorage.getItem('i18nextLng');

    useEffect(() => {
        ApiGet.actions.PesanBod()
            .then((response) => {
                if (responseResult(response)) {
                    setState(responseResult(response))
                }
            })
        return () => {

        }
    }, [])


    const customChildren = (data) => {
        // console.log('customChildren', data)
        return data.map((_val, _index) => {
            if (_val) {
                return (
                    // <div key={_index} className="bg-[#F1F1F1] rounded-lg py-[30px] px-[75px]" >
                    //     <div className="flex flex-row justify-center">
                    //         <img src={uAPI + _val?.PIC} alt={_val?.ALT} style={{
                    //             width: '250px',
                    //             height: '300px',
                    //             marginRight: '50px'
                    //         }} />
                    //         <div className="flex flex-col justify-center whitespace-normal">
                    //             <div className="text-[20px] font-semibold">
                    //                 {_val?.NAME}
                    //             </div>
                    //             <div className="text-[18px]">
                    //                 {_val?.JABATAN}
                    //             </div>
                    //             <blockquote className="quotes-home text-justify italic mt-6">
                    //                 {_val?.QUOTES}
                    //             </blockquote>
                    //         </div>
                    //     </div>
                    // </div>
                    <div key={_index} className="flex flex-col pt-[30px] pb-[30px] pr-[71px] w-[1020px] items-center md:flex-row md:justify-evenly bg-[#F1F1F1] rounded-lg" >
                        <div className="basis-1/2 img-profile">
                            <img className="flex flex-row justify-center" src={uAPI + _val?.PIC} alt={_val?.ALT} />
                        </div>
                        <div className="basis-1/2 flex flex-col justify-center">
                            <div className="text-[20px] font-semibold">
                                {_val?.NAME}
                            </div>
                            <div className="text-[18px]">
                                {_val?.JABATAN}
                            </div>
                            <blockquote className="quotes-home text-justify italic mt-6">
                                {_val?.QUOTES}
                            </blockquote>
                        </div>
                    </div>
                )
            }
            return _val;
        })
    }

    const customIndicators = (index) => {
        // console.log('customIndicators', index)
        return (
            <div className="indicator-dot"></div>
        );
    }

    return (
        <>
            {
                state.length > 0 && (
                    // <div className="px-[70px]">
                    //     <SlideshowCustom data={state} showItem={1} customChildren={customChildren} withArrow={true} withDots={true} autoSlide />
                    // </div>
                    <div className="px-[20px] md:px-[70px]">
                        <ReactSlideshow slidesToShow={1} slidesToScroll={1} responsive={[]} indicators={true} customIndicators={customIndicators}
                            prevArrow={
                                <div className="bottom-arrow"></div>
                            }
                            nextArrow={
                                <div className="bottom-arrow"></div>
                            }
                        >
                            {
                                state.map((_val, _index) => {
                                    return (
                                        <div key={_index} className="flex flex-col mx-[15px] p-[30px] my-[30px] items-center md:flex-row md:justify-evenly bg-[#F1F1F1] rounded-xl" >
                                            <div className="basis-1/2 img-profile">
                                                <img className="flex flex-row justify-center" src={uAPI + _val?.PIC} alt={_val?.ALT} />
                                            </div>
                                            <div className="basis-1/2 flex flex-col justify-center">
                                                <div className="text-[20px] font-semibold">
                                                    {_val?.NAME}
                                                </div>
                                                <div className="text-[18px]">
                                                    {lang === "en" ? _val?.jabatan_en : _val?.JABATAN}
                                                </div>
                                                <blockquote className="quotes-home text-justify italic mt-6">
                                                {lang === "en" ? _val?.quotes_en : _val?.QUOTES}
                                                </blockquote>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </ReactSlideshow>
                    </div>
                )
            }

        </>
    )
}
export default ProfilHome;