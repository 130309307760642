import useScript from 'components/reuseable/useScript';
import React, { useEffect } from 'react';

const MediaSocialHome = () => {
    useScript('https://embedsocial.com/cdn/ht.js')
    // useScript('https://embedsocial.com/js/iframe.js')
    // useScript('https://embedsocial.com/cdn/universal-popup.js')
    // const ebFunc = (d, s, id) => {
    //     var js;
    //     if (d.getElementById(id)) { return; }
    //     js = d.createElement(s);
    //     js.id = id;
    //     js.src = "https://embedsocial.com/cdn/ht.js";
    //     js.async = true;
    //     d.getElementsByTagName("head")[0].appendChild(js);
    // }

    // useEffect(() => {
    //     const test = ebFunc(document, "script", "EmbedSocialHashtagScript");
    //     return () => {
    //     }
    // }, []);

    // const cekFrame = document.getElementsByClassName('instagram');
    // console.log('cekFrame', cekFrame)
    const frame = document.getElementsByClassName('embedsocial-hashtag-iframe');
    for (const key in frame) {
        if (frame.hasOwnProperty(key)) {
            // console.log(`${key}: ${frame[key]}`);
            console.log('frame', frame[key])
            frame[key].setAttribute("scrolling", "")
            frame[key].setAttribute("class", "myembed")
            // frame[key].style.height = "600px";
            // frame[key].style.overflow = "scroll";
        }
    }
    // useEffect(() => {
    //     // console.log('frame', frame)
    //     // if (frame['length'] > 0) {
    //         // console.log('frame', frame)
    //         for (const key in frame) {
    //             if (frame.hasOwnProperty(key)) {
    //                 // console.log(`${key}: ${frame[key]}`);
    //                 console.log('frame', frame[key])
    //                 frame[key].setAttribute("scrolling","")
    //             }
    //         }

    //     // }
    // }, [frame])

    // console.log('framess', frame)

    return (
        <>

            <div className="flex flex-row items-center justify-center py-10">
                <div className="basis-2/3 bg-white mr-5" >
                    {/* <iframe title="instagram" src="https://embedsocial.com/api/pro_hashtag/780ad8766a8afd4abbbd7789b68f09a9c886eb50" width="100%" height="600px" /> */}
                    <div className="instagram embedsocial-hashtag z-0" style={{ width: '100%', height: '600px' }} data-ref="780ad8766a8afd4abbbd7789b68f09a9c886eb50"></div>
                </div>
                <div className="basis-1/3 bg-white">
                    {/* <iframe title="twitter" src="https://embedsocial.com/api/pro_hashtag/e5ef289c21a93bdf9af13c7105dd4227b9c066cb" width="100%" height="600px" /> */}
                    <div className="twitter embedsocial-hashtag z-0" style={{ width: '100%', height: '600px' }} data-ref="e5ef289c21a93bdf9af13c7105dd4227b9c066cb"></div>
                </div>
            </div>
        </>
    )
}

export default MediaSocialHome;