import {SETMENULISTPPID, RESETMENULISTPPID, SETDETAILSPPID, RESETDETAILSPPID, SETDETAILBYID, RESETDETAILBYID, SETFILEBYID, RESETFILEBYID} from "./ppidTypes";

const initialState = {
    menuList : [],
    details: null,
    detailById: null,
    fileById: null
}

const reducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case SETMENULISTPPID:
            return{
                ...state,
                menuList: payload
            };
        case RESETMENULISTPPID:
            return{
                ...state,
                menuList: payload
            }
        case SETDETAILSPPID:
            return{
                ...state,
                details: payload
            }
        case RESETDETAILSPPID:
            return{
                ...state,
                details: payload
            }
        case SETDETAILBYID:
            return{
                ...state,
                detailById: payload
            }
        case RESETDETAILBYID:
            return{
                ...state,
                detailById: payload
            }
        case SETFILEBYID:
            return{
                ...state,
                fileById: payload
            }
        case RESETFILEBYID:
            return{
                ...state,
                fileById: payload
            }
        default:
            return state;
    }
};

export default reducer;