import React from "react";
import Chatbot from "react-chatbot-kit";
import 'react-chatbot-kit/build/main.css'

import config from "../../reuseable/ComponentChatBot/chatbotConfig";
import MessageParser from "../../reuseable/ComponentChatBot/MessageParser";
import ActionProvider from "../../reuseable/ComponentChatBot//ActionProvider";

const ChatBot = () => {
  return (
    <div className="Chatbot">
      <Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
      />
    </div>
  );
}

export default ChatBot;