import React, { useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {getDetailsPpid, getMenuListPpid, getDetailById, getFileById} from "redux/ppid/ppidActions"
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import ChatBox from "../PopUp/ChatBox";
import { useSelector, useDispatch } from "react-redux";
import Submenu from "./component/submenu"
import Detail from "./component/detail"
import LayananPublik from "./LayananPublik";

const PPID = ({ children }) => {
  const lang = localStorage.getItem('i18nextLng');
  const location = useLocation();
  const { pathname } = location;

  
  // const [titleList, setTitleList] = useState([]);
  // const [detail, setDetail] = useState([]);
  let selector = useSelector((state)=>state.ppid)
  const dispatch = useDispatch()

  const handleOnClick = (id) => {
    if(id){
      let params = id? "?id="+id : "" 
      let paramsFile = id? "?id_table_menu="+id : "" 
      dispatch(getDetailById(params))
      dispatch(getFileById(paramsFile))
    }
    } 
    console.log("SELECTOR",selector)
    console.log("pathname",pathname)

  const bgTitle = (title) => {
    switch (title) {
      case "Profil":
        return "bg-profil";
      case "GCG":
        return "bg-gcg";
      case "Layanan":
        return "bg-layanan";
      case "Fasilitas":
        return "bg-fasilitas";
      case "Hubungan Investor":
        return "bg-hubungan";
      case "Media & Publikasi":
        return "bg-media";
      case "TJSL":
        return "bg-tjsl";
      default:
        return "bg-ppid";
    }
  };
  useEffect(()=> {
    dispatch(getDetailsPpid())
    dispatch(getMenuListPpid())
    dispatch(getDetailById())
    dispatch(getFileById())
  },[dispatch])
  useMemo(() => {
    let params = "?id="+1
    dispatch(getDetailById(params))
  }, [location])
  // console.log("OLOCATION", selector?.detailById?.map((va)=> `${va.id}`) == 9)
selector?.detailById?.map((vals)=> console.log(vals.menu) )
  return (
    <>
      {/* === akhir tag container === */}
      <div className="mt-[110px] ">

        {/* ===== Awal Banner Title ===== */}
        <div className={`${bgTitle( "PPID" )} flex flex-col justify-center items-center`}>
          <div className="text-white text-[40px] font-semibold uppercase text-center">
            {/* {`PPID`} */}
            {selector?.detailById?.map((vals)=> `${lang === "en" ? vals.menu_en : vals.menu}` )}
          </div>
        </div>
        {/* ===== Akhir Banner Title ===== */}

        {/* ===== Awal menu side bar dan konten ===== */}
        <div className="flex ">
        
          {/*  ====== start Div untuk menu side bar ======*/}
          <div className="flex-4 sectio md:grid md:grid-cols-1 p-6 md:block">
                  <div className="hidden md:flex md:flex-col md:mr-2">
                    <div className="text-inactive text-[17px] mb-4">
                    {selector?.menuList?.map((val) => (
                        <Disclosure>
                            {({ open }) => (
                                <div key={val.id}>
                                    <Disclosure.Button
                                    className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] ml-10 p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 gap-7"
                                    // onClick={setIdTitle(val.id)}
                                    >
                                    <div onClick={() => handleOnClick(val?.submenu?.length > 0 ? null : val.id)}>
                                        <div className="my-1 text-[17px] ">{`${lang === "en" ? val.menu_en : val.menu}`}</div>
                                    </div>
                                    {val?.submenu?.length > 0? (  
                                        <ChevronUpIcon
                                            className={`  ${
                                                open ? "rotate-180 transform" : ""
                                            } h-5 w-5 text-primary-500`}
                                        />) : null}
                                    </Disclosure.Button>
                                    {val?.submenu?.length > 0? <Submenu items={val.submenu} /> : null}
                                </div>
                            )}
                        </Disclosure>
                    ))}
                    </div>
                  </div>
          </div>
          {/*  ====== akhir Div untuk menu side bar ======*/}
          
          {/* ===== Garis Pemisah ===== */}
          <div className="border-l border-gray-300"></div>
          
          {/* ===== Awal isi Detail ===== */}
          {
          //  <LayananPublik/>
          selector?.detailById?.map((va)=> `${va.id}`) == "11" ? <LayananPublik/> : <Detail detail={selector?.detailById} file={selector?.fileById}/>
          }
            
          {/* ===== Akhir isi Detail ===== */}

        </div>
        {/* Akhir menu side bar dan konten */}

      </div>
      {/* === akhir tag container === */}
      <ChatBox />
    </>
  );
  
}
export default PPID;
