import React, { useEffect } from "react";
import { getJenisLayanan, resetLayanan } from "redux/layanan/layananActions";
import useRedux from "redux/useRedux";
import icSelengkapnya from 'assets/images/icons/diagonal-arrow-right.svg';
import FroalaEditorView from "components/reuseable/FormInput/FroalaEditorTextArea";
import { uAPI } from "api/config";
import SlideshowCustom from "components/reuseable/Slideshow/SlideshowCustom";
import { useNavigate } from "react-router-dom";
import { slugUrl, formatText, getTextContent } from "utils/myFunc";
import batik from 'assets/images/batik.jpg';
import { useTranslation } from 'react-i18next';

const LayananHome = () => {
    const lang = localStorage.getItem('i18nextLng');
    const {
        dispatch,
        layanan: {
            jenisLayanan
        }
    } = useRedux();
    let navigate = useNavigate();

    useEffect(() => {
        dispatch(getJenisLayanan({
            params: {
                status: 1,
            }
        }));
        return () => {
            dispatch(resetLayanan())
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onClickMenu = (val) => {
        // console.log('onClickMenu', val)
        val?.title && navigate(slugUrl(val?.title))
    }

    const customChildren = (data) => {
        // console.log('customChildren', data)
        return data.map((_val, _index) => {
            if (_val) {
                return (
                    <div key={_index} className="mt-10 md:-mt-[50px] mx-[10px]">
                        <div className="flex flex-row w-full md:w-[480px] min-h-[280px]  rounded-lg bg-white drop-shadow-md">
                            <div className="md:basis-2/3 flex flex-col relative p-[30px]">
                                <div className="text-primary text-[20px] font-semibold mb-[10px]">
                                    {_val?.title}
                                </div>
                                <div className="text-[16px] text-justify mb-6">
                                    <FroalaEditorView
                                        model={_val?.description}
                                    />
                                </div>
                                <div className="absolute bottom-[30px] right-[30px] flex flex-row text-active text-right cursor-pointer items-center" onClick={() => onClickMenu(_val)}>
                                    Selengkapnya <img className="ml-2" src={icSelengkapnya} alt="selengkapnya" />
                                </div>
                            </div>
                            <div className="basis-1/3 ml-2">
                                <img src={uAPI + _val?.image} alt={_val?.image_alt} />
                            </div>
                        </div>
                    </div>
                )
            }
            return _val;
        })
    }

    const trimTextForResponsive = (text) => {
        const content = getTextContent(text)
        return window.screen.width <=1230 ? `<p style='font-size: 14px'>${formatText(content, 80)}</p>` : content
    }
    console.log("jenisLayanan",jenisLayanan)
    const { t, i18n } = useTranslation(); // Mengakses fungsi terjemahan
    return (
        <>
            <div className="flex flex-wrap flex-row justify-center">
                {
                    jenisLayanan?.map((val, ind) => {
                        return (
                            <div key={ind} className="mt-10 md:-mt-[50px] mx-[10px]">
                                {/* <div className="flex flex-row w-full md:w-[480px] min-h-[280px] rounded-lg bg-white drop-shadow-md"> */}
                                <div className="flex flex-row w-full md:w-[580px] md:h-80 rounded-lg bg-white drop-shadow-md">
                                    <div className="basis-2/3 flex flex-col relative p-[30px] ">
                                        <div className="text-primary md:text-[20px] sm:text-[16px] font-semibold mb-[10px]">
                                            {lang === "en" ? val?.title_en : val?.title}
                                        </div>
                                        <div className="text-justify mb-8">
                                            <FroalaEditorView
                                                model={trimTextForResponsive(lang === "en" ? val?.description_en : val?.description)}
                                            />
                                        </div>
                                        <div className="absolute bottom-[30px] right-[30px] flex flex-row text-active text-right cursor-pointer items-center text-[14px] md:text-[16px]" onClick={() => onClickMenu(val)}>
                                            {/* Selengkapnya <img className="ml-2 h-[14px] md:h-[16px]" src={icSelengkapnya} alt="selengkapnya" /> */}
                                            {t('more')} <img className="ml-2 h-[14px] md:h-[16px]" src={icSelengkapnya} alt="selengkapnya" />
                                        </div>
                                    </div>
                                    <div className="basis-1/3 ml-2">
                                        <img src={uAPI + val?.image} alt={val?.image_alt} style={{ height: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {/* {
                    jenisLayanan?.length > 0 && <SlideshowCustom data={jenisLayanan} showItem={2} customChildren={customChildren} withArrow={jenisLayanan?.length >= 2 ? false : true} withDots={jenisLayanan?.length >= 2 ? false : true} />
                } */}

            </div>
            <div className="absolute bottom-[-200px] left-[-165px] -z-10">
                <img src={batik} alt={'batik'} style={{
                    width: '300px',
                    height: '300px'
                }} />
            </div>
        </>
    )
}
export default LayananHome