import { useOutletContext } from "react-router-dom";
import BasicDropdown from "components/reuseable/Dropdown/BasicDropdown";
import SelectDropdown from "components/reuseable/Dropdown/SelectDropdown"
import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  setActiveMenuSidebar,
  setMenuSidebar,
} from "redux/global/globalActions";
import { setAnakPerusahaan } from "redux/profil/profilActions";
import useRedux from "redux/useRedux";
// import icSelected from "./assets/images/icons/icon-selected.svg";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import ppidDoc from "assets/images/icons/ppid-doc.svg";
import ppidInfo from "assets/images/icons/ppid-info.svg";
import ppidCheck from "assets/images/icons/ppid-check.svg";
import bgFooter from "assets/images/bg-footer-ppid.svg"
import ChatBox from "../../pages/PopUp/ChatBox";
import {t} from "i18next"

const LayananPublik = () => {
  const lang = localStorage.getItem('i18nextLng');
    const location = useLocation();
  const { pathname } = location;
  // console.log('pathname', pathname)
  const {
    dispatch,
    global: { menu, menuSidebar, activeMenuSidebar },
    profil: { anakPerusahaan },
  } = useRedux();

  let navigate = useNavigate();

  const [open, setOpen] = useState(false);
  console.log('menu', menu);
  console.log('menuSidebar', menuSidebar);
  console.log('activeMenuSidebar', activeMenuSidebar);

  let pathFind = "";
  const findSubMenu = menu.find((val, ind) => {
    let child = val?.child;
    if (child?.length == 0) {
      pathFind = child?.find(
        (val) => val?.path.split("/")[1] === pathname.split("/")[1]
      );
      return child?.find(
        (val) => val?.path.split("/")[1] === pathname.split("/")[1]
      );
    }
    return null;
  });
  useEffect(() => {
    if (findSubMenu) {
      dispatch(setMenuSidebar(findSubMenu));
    }
    if (pathFind) {
      dispatch(setActiveMenuSidebar(pathFind));
    }
    return () => {
      dispatch(setMenuSidebar(null));
      dispatch(setActiveMenuSidebar(null));
    };
  }, [findSubMenu, pathFind]); // eslint-disable-line react-hooks/exhaustive-deps
  console.log('pathnames', pathFind?.path)
  console.log('findSubMenu', findSubMenu)
  console.log('pathFind', pathFind)

  const anakPerusahaanClick = (data) => {
    dispatch(
      setAnakPerusahaan({
        selected: data,
      })
    );
  };
 
  const bgTitle = (title) => {
    switch (title) {
      case "Profil":
        return "bg-profil";
      case "GCG":
        return "bg-gcg";
      case "Layanan":
        return "bg-layanan";
      case "Fasilitas":
        return "bg-fasilitas";
      case "Hubungan Investor":
        return "bg-hubungan";
      case "Media & Publikasi":
        return "bg-media";
      case "TJSL":
        return "bg-tjsl";
      default:
        return "bg-ppid";
    }
  };
  let other = [];

  const menuSidebarss = ["E-PPID","Profile","Regulasi","Maklumat","Informasi Publik","Layanan Publik","Agenda","Registrasi Permohonan"];
  const menuSidebars = ["E-PPID"];
  let test2 = menuSidebar?.child.sort((a, b) => {
    return a.id - b.id;
  });
  
  //Bagian set data untuk di kirim ke email
  const [formData, setFormData] = useState({
   jenisPemohon: '',
   jenisIdentitas: '',
   namaIdentitas: '',
   namaLengkap: '',
   npwp: '',
   pekerjaan: '',
   telp: '',
   fax: '',
   alamat: ''
  })

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData({
      ...formData, [name]: value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();


    try {
      const formdata = new FormData();
      // formdata.append("to", "agit.zaini@gmail.com");
      formdata.append("to", "infosolusilogistik@pelindo.co.id");
      formdata.append("topic", "PPID");
      formdata.append("payload", `jenisPemohon: ${formData.jenisPemohon},
          jenisIdentitas: ${formData.jenisIdentitas},
          namaIdentitas: ${formData.namaIdentitas},
          namaLengkap: ${formData.namaLengkap},
          npwp: ${formData.npwp},
          pekerjaan: ${formData.pekerjaan},
          telp: ${formData.telp},
          fax: ${formData.fax},
          alamat: ${formData.alamat}`);
      
      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
      };

fetch("https://pelindo-hub.pelindo.co.id/psl/email", requestOptions)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));

    } catch (error) {
      console.log('Error:', error)
    }
  }
    return (
        <>
                            <div className="image-container p-12">
                              <form onSubmit={handleSubmit}>
                                <div class="space-y-8">
                                  <div class="border-b border-gray-900/10 ">
                                    <h2 class="text-base font-semibold leading-7 text-gray-900">{t('registrasi_permohonan_informasi')}</h2>
                                    <p class="mt-1 text-sm leading-6 text-gray-600">{t('detail_formulir')}</p>
                                  </div>
                                  <div class="border-b border-gray-900/10 pb-12">
                                    <h2 class="text-base font-semibold leading-7 text-gray-900">
                                      {t('data_pemohon')}
                                    </h2>
                                    <div class="mt-5 grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-6">
                                      
                                      <div class="sm:col-span-3">
                                        <label for="jenis-pemohon" class="block text-sm font-medium leading-6 text-gray-900">
                                          {t('jenis_pemohon')}
                                        </label>
                                        <div class="mt-2">
                                          <select id="jenisPemohon" onChange={handleChange} value={formData.jenisPemohon} name="jenisPemohon" autocomplete="jenis-pemohon-name" class="block w-full rounded-md border-0 py-3 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                            <option value={`Perorangan`}>{t('perorangan')}</option>
                                            <option value={`Kelompok Orang`}>{t('kelompok_orang')}</option>
                                            <option value={`Badan Hukum`}>{t('badan_hukum')}</option>
                                          </select>
                                        </div>
                                      </div>
                                      
                                      <div class="sm:col-span-3">
                                        <label for="jenisIdentitas" class="block text-sm font-medium leading-6 text-gray-900">
                                          {t('jenis_identitas')}
                                        </label>
                                        <div class="mt-2">
                                          <select id="jenisIdentitas"  value={formData.jenisIdentitas} onChange={handleChange} name="jenisIdentitas" autocomplete="jenis-identitas-name" class="block w-full rounded-md border-0 py-3 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                              <option value={`KTP`}>KTP</option>
                                              <option value={`Pengesahan Bahan Usaha`}>{t('pengesahan_badan_usaha')}</option>
                                              <option value={`Surat Kuasa`}>{t('surat_kuasa')}</option>
                                              <option value={`Badan Publik`}>{t('badan_publik')}</option>
                                          </select>
                                        </div>
                                      </div>
                                      
                                      <div class="sm:col-span-6">
                                        <label for="namaIdentitas" class="block text-sm font-medium leading-6 text-gray-900">{t('nama_identitas')}</label>
                                        <div class="mt-2">
                                          <input id="namaIdentitas"  value={formData.namaIdentitas} onChange={handleChange} name="namaIdentitas" type="text" autocomplete="text" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                        </div>
                                      </div>
                                      
                                      <div class="sm:col-span-6">
                                        <label for="namaLengkap" class="block text-sm font-medium leading-6 text-gray-900">{t('nama_lengkap')}</label>
                                        <div class="mt-2">
                                          <input id="namaLengkap" value={formData.namaLengkap} onChange={handleChange} name="namaLengkap" type="text" autocomplete="text" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                        </div>
                                      </div>
                                      
                                      <div class="sm:col-span-3">
                                        <label for="npwp" class="block text-sm font-medium leading-6 text-gray-900">{t('npwp')}</label>
                                        <div class="mt-2">
                                          <input type="text" onChange={handleChange} name="npwp" value={formData.npwp} id="npwp" autocomplete="given-name" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                        </div>
                                      </div>
                              
                                      <div class="sm:col-span-3">
                                        <label for="pekerjaan" class="block text-sm font-medium leading-6 text-gray-900">{t('pekerjaan')}</label>
                                        <div class="mt-2">
                                          <input type="text" value={formData.pekerjaan} onChange={handleChange} name="pekerjaan" id="pekerjaan" autocomplete="family-name" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                        </div>
                                      </div>
                                      
                                      <div class="sm:col-span-3">
                                        <label for="telp" class="block text-sm font-medium leading-6 text-gray-900">{t('no_telp')}</label>
                                        <div class="mt-2">
                                          <input type="text" onChange={handleChange} name="telp" value={formData.telp} id="telp" autocomplete="given-name" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                        </div>
                                      </div>
                              
                                      <div class="sm:col-span-3">
                                        <label for="fax" class="block text-sm font-medium leading-6 text-gray-900">{t('no_fax')}</label>
                                        <div class="mt-2">
                                          <input type="text" onChange={handleChange} name="fax" value={formData.fax} id="fax" autocomplete="family-name" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                        </div>
                                      </div>
                              
                                      <div class="sm:col-span-6">
                                        <label for="nama-lengkap" class="block text-sm font-medium leading-6 text-gray-900">{t('alamat')}</label>
                                        <div class="mt-2">
                                          <input id="alamat" onChange={handleChange} name="alamat" value={formData.alamat} type="text" autocomplete="text" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                        </div>
                                      </div>
                              
                                    </div>
                                  </div>
                              
                                </div>
                              
                                <div class="mt-6 flex items-center justify-center gap-x-6">
                                  {/* <button type="button" class="text-sm font-semibold leading-6 text-gray-900">Cancel</button> */}
                                  <button type="submit" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">{t('submit_registrasi_permohonan')}</button>
                                </div>
                              </form>
                            </div>
        </>
    )
}
export default LayananPublik;