import React, { useEffect, useRef, useState } from "react";
import SlideshowHeader from "components/reuseable/Slideshow/SlideshowHeader";
import BeritaHome from "./Beranda/BeritaHome";
import AnakPerusahaanHome from "./Beranda/AnakPerusahaanHome";
import ProfilHome from "./Profil/ProfilHome";
import DividerSection from "components/reuseable/DividerSection";
import TestimoniHome from "./Beranda/TestimoniHome";
import TentangKamiHome from "./Beranda/TentangKamiHome";
import WilayahKerjaHome from "./Beranda/WilayahKerjaHome";
import LayananHome from "./Beranda/LayananHome";
import ChooseUs from "./Beranda/ChooseUs";
import ChatBox from "./PopUp/ChatBox";
import Whatsapp from "./PopUp/Whatsapp";
import StandardModal from "utils/StandarModal";
import ApiGet from "api/ApiGet";
import { uAPI } from "api/config";
import ReactSlideshow from "components/reuseable/Slideshow/ReactSlideshow";
import MediaSocialHome from "./Beranda/MediaSocialHome";
import { useTranslation } from 'react-i18next';
import ChatBot from "./PopUp/Chatbot";




const Home = () => {
    const myRef = useRef(null)
    const counting = useRef(null)

    const [open, setOpen] = useState(true);
    const [imgPopUp, setImgPopUp] = useState([]);

    var image
    useEffect(() => {
        ApiGet.actions.Popup()
            .then((response) => {
                let url = response.responseResult
                console.log(url, '-------------')
                // let url="/images/fd9703169fa3c5062bc92971f-otto-(3).png"
                setImgPopUp(url)
                // setImgPopUp(`${uAPI}${url.image}`)
                //return image = `${uAPI}${url.image}`
                //console.log(setImgPopUp, '-------------')
                // if (responseResult(response)) {
                //     setData(responseResult(response))
                // }
            })
    }, [])
    const { t, i18n } = useTranslation(); // Mengakses fungsi terjemahan
    // console.log(image, '==========', imgPopUp);
    // imgPopUp="http://10.8.3.59:4001/images/3b3370dd4307508123b2e9900-direktur_utama.png"
    return (
        <>
            <StandardModal closeModal={() => setOpen(false)} open={open} className={`mt-20 rounded-lg place-content-center bg-transparent w-[511.05px] h-[684px] `}>
                <div className=" bg-white rounded-lg ">
                    <div className="p-4">

                        <div className="w-[350px] md:w-full">
                            {imgPopUp.length ? <ReactSlideshow indicators={false} arrows={false} slidesToShow={1} slidesToScroll={1} responsive={[]}
                            >
                                {
                                    imgPopUp?.map((_val, _index) => {
                                        // console.log(_val, "valuee")
                                        return (
                                            <div>
                                                <div className=" flex items-center gap-2 px-[1px]">
                                                    <div>
                                                        <svg width="3" height="15" viewBox="0 0 3 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect y="7.5" width="7.5" height="3" transform="rotate(-90 0 7.5)" fill="#36B4E5" />
                                                            <rect y="15" width="7.5" height="3" transform="rotate(-90 0 15)" fill="#828585" />
                                                        </svg>
                                                    </div>
                                                    <div className="font-semibold text-[20px] text-[#828585]">
                                                        {_val.title}
                                                    </div>
                                                </div>
                                                <div className="border-b-2 mt-1"></div>
                                                <div key={_index} className="cursor-pointer px-[1px]" onClick={() => {
                                                    // if (_val?.company_website) {
                                                    //     window.open(_val?.company_website ? _val?.company_website : "#", "_blank")
                                                    // }
                                                }} >
                                                    <img className="w-[350px] md:w-full" src={uAPI + _val.image} alt={_val.title} />
                                                </div>
                                            </div>

                                        )
                                    })
                                }
                            </ReactSlideshow> : ""}
                            {/* {console.log(imgPopUp, '++++')} */}
                            {/* <img className="w-full" src={imgPopUp}  /> */}
                        </div>
                    </div>
                </div>
            </StandardModal>
            <div className="section relative">
                <SlideshowHeader />
                <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-white text-center px-[20px] md:px-[48px]"
                    style={{ textShadow: '0px 4px 4px rgba(0, 0, 0, 0.8)' }}
                >
                    <div className="text-[30px] leading-[30px] 
                    md:text-[48px] md:leading-[50px] uppercase font-bold
                    ">
                        Integrated Logistics Ecosystem Player
                    </div>
                    <div className=" text-[15px] leading-[15px] mt-[20px] 
                    md:text-[20px] md:leading-[20px] md:mt-[30px]
                    ">
                        {/* Solusi Logistik Terdepan yang Mendorong Laju Perdagangan dan Ekonomi di Indonesia */}
                       {t('detail01')}
                    </div>
                </div>
            </div>
            <div className="relative px-[20px]
            md:px-[48px]
            ">
                <LayananHome />
            </div>

            <div className="section relative">
                <DividerSection />
                <div className="flex flex-row items-center justify-center pb-10">
                    {/* Tentang Kami */}
                    <div className="hTitle">{t('hTitle01')}</div>
                </div>
                <TentangKamiHome />
            </div>
            <div className="section section-min-h-85">
                <DividerSection />
                <div className="flex flex-row items-center justify-center pb-10">
                    {/* <div className="hTitle">Mengapa Memilih Kami?</div> */}
                    <div className="hTitle">{t('hTitle02')}</div>
                </div>
                <ChooseUs scroll={myRef} />
                {/* <div className="choose-us">
                    <img src={chooseUs} alt="choose-us" style={{ width: '100%' }} />
                    <div className="top-left">
                        <div className="flex flex-row">
                            <div className="mr-2">
                                <img src={chooseUsLoc} alt='choose-us-loc' />
                            </div>
                            <div className="flex flex-col">
                                <div className="text-primary text-[18px] font-semibold">Lokasi Fasilitas yang Strategis</div>
                                <div className="text-[14px] text-justify w-[220px]">Fasilitas yang terintegrasi langsung dengan terminal & pelabuhan</div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-left">
                        <div className="flex flex-row">
                            <div className="mr-2">
                                <img src={chooseUsMap} alt='choose-us-map' />
                            </div>
                            <div className="flex flex-col">
                                <div className="text-primary text-[18px] font-semibold">Wilayah Operasi yang Luas</div>
                                <div className="text-[14px] text-justify w-[220px]">Wilayah operasi yang tersebar di seluruh Indonesia</div>
                            </div>
                        </div>
                    </div>
                    <div className="top-right">
                        <div className="flex flex-row">
                            <div className="mr-2">
                                <img src={chooseUsCheck} alt='choose-us-check' />
                            </div>
                            <div className="flex flex-col">
                                <div className="text-primary text-[18px] font-semibold">Perusahaan yang tersertifikasi</div>
                                <div className="text-[14px] text-justify w-[220px]">Mengutamakan pelayanan dengan mutu terbaik di sektor logistik</div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-right">
                        <div className="flex flex-row">
                            <div className="mr-2">
                                <img src={chooseUsHand} alt='choose-us-hand' />
                            </div>
                            <div className="flex flex-col">
                                <div className="text-primary text-[18px] font-semibold">End to End Services</div>
                                <div className="text-[14px] text-justify w-[220px]">Menyediakan layanan yg mencakup first mile hingga last mile</div>
                            </div>
                        </div>
                    </div>
                    <div className="centered-bottom">
                        <div className="flex flex-row">
                            <div className="mr-2">
                                <img src={chooseUsPelindo} alt='choose-us-pelindo' />
                            </div>
                            <div className="flex flex-col">
                                <div className="text-primary text-[18px] font-semibold">Bagian dari Pelindo Group</div>
                                <div className="text-[14px] text-justify w-[220px]">BUMN kepelabuhanan yang terbesar di Indonesia</div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="section">
                <DividerSection />
                <div className="flex flex-row items-center justify-center pb-10">
                    {/* <div className="hTitle">Pesan BOD</div> */}
                    <div className="hTitle">{t('pesan_bod')}</div>
                </div>
                <ProfilHome />
                <div className="flex flex-row items-center justify-center py-10">
                    <div className="hTitle">{t('testimoni')}</div>
                </div>
                <TestimoniHome />
            </div>
            <div className="section bg-[#F4F4F4]">
                <div className="flex flex-row items-center justify-center py-10">
                    <div className="hTitle">{t('kabar_terbaru')}</div>
                </div>
                <BeritaHome />
                <div className="flex flex-row items-center justify-center py-10">
                    <div className="hTitle">{t('sosial_media')}</div>
                </div>
                <MediaSocialHome />
                {/* <iframe title="sosmed" src="https://embedsocial.com/api/pro_hashtag/79a01ae1fbbfeb59ad38256f3d555277cc8115b6" width="100%" height="600px" /> */}
            </div>
            <div className="section" id="wilayah">
                <div className="flex flex-row items-center justify-center py-10">
                    <div className="hTitle" ref={counting}>{t('wilayah_kerja')}</div>
                </div>
                <WilayahKerjaHome countRef={counting} />
                <div className="flex flex-row items-center justify-center py-10">
                    <div className="hTitle" ref={myRef}>{t('anak_perusahaan')}</div>
                </div>
                <AnakPerusahaanHome />
            </div>

            {/* <Whatsapp /> */}
            <ChatBox />
            {/* <ChatBot /> */}
        </>
    )
}


export default Home;