import React, { useEffect, useState } from "react";
import FroalaEditorView from "components/reuseable/FormInput/FroalaEditorTextArea";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import ppidDoc from "assets/images/icons/ppid-doc.svg";
import ppidInfo from "assets/images/icons/ppid-info.svg";
import ppidCheck from "assets/images/icons/ppid-check.svg";
import icons from "assets/images/icons/icons.svg";
import { informasiBerkala } from "assets/filePDF";
import { downloadFile, getFileExtension } from "utils/myFunc";
import { uAPI } from "api/config";




function Detail({detail, file}) {
  const gradientStyle = {
    background: 'linear-gradient(to right, #1C3865, #1C80B9, #36B4E5)'
  };
  const lang = localStorage.getItem('i18nextLng');

  const [isOpen, setIsOpen] = useState({dropdownOpen: false, dropdownIndex: 0 }) 
  const toggleDropdown = (index) => { 
    setIsOpen("INDEX",index)
    if (index === isOpen.dropdownIndex) {
      setIsOpen({dropdownIndex: index, dropdownOpen: false})  
    }
    setIsOpen({dropdownIndex: index, dropdownOpen: true})

  }
        return(
            <div className="flex-1 p-6 md:grid-cols-9 md:block">
                <div className="flex-1 flex-col sm:my-5 mx-2 md:mx-20 text-justify">
                {detail && <FroalaEditorView model={lang === "en" ? detail[0]?.description_en : detail[0]?.description} />}
                {file &&
                  file?.map((val, ind) => {
                      return (
                        <div>
                            <div className="flex flex-col my-10 mx-2 text-justify" style={{ borderTopLeftRadius: '1rem',borderTopRightRadius: '1rem'}}>
                            <div class="mb-2 lg:border lg:rounded-lg rounded-sm"style={{width:'100%'}}>
                                <div  class={`text-white  align-middle font-medium lg:rounded-t-lg lg:rounded-lg text-lg px-2 lg:py-4 py-2 flex flex-row-reverse cursor-pointer hover:opacity-90 border-b  css-ck7407 min-w-[1075px]`} onClick={() => toggleDropdown(ind)} style={gradientStyle}>
                                    <ChevronUpIcon
                                      className={`${
                                        !isOpen?.dropdownOpen ? "rotate-180 transform" : ""
                                      } h-5 w-5 text-primary-500`}
                                    />
                                    <div class="flex-auto mt-1 text-lg">{val?.title}</div>
                                    <div class="px-2 ">
                                        <img src={icons} className=" flex justify-left" alt="ppic-info" />
                                    </div>
                                    </div>
                                    
                                    <div class={`lg:px-4 px-2 py-2 text-justify text-left text-gray-800 lg:mb-5 mb-3 bg-white block  ${isOpen?.dropdownIndex === ind ? ' ': 'hidden'}`} >
                                      {val?.subdetail && val?.subdetail?.map((value, i) => {
                                        return(
                                          <span>
                                            <div class="flex lg:flex-row lg:justify-between flex-col border my-3 lg:px-8 px-4 py-2 lg:rounded-xl rounded-md cursor-pointer font-medium text-gray-700 hover:bg-gray-100">
                                              <div class="flex items-center lg:mr-8 lg:text-left text-center">
                                                <div style={{ marginRight: '10px'}}>
                                                  <img src={ppidDoc} alt="ppic-info" />
                                                </div> 
                                                {value?.title}
                                              </div>
                                              <div class="flex justify-center ">
                                                {
                                                  value?.path.includes("/files") ?
                                                  (                                                
                                                    <>
                                                      <a href={uAPI + value?.path} target="_blank" class="flex items-center border rounded-md py-1 px-8 text-white lg:text-sm text-xs css-18s1imn" style={{ backgroundColor: '#2FB6E9' }}>View</a>
                                                      <a onClick={() => downloadFile(value?.path)} target="_blank" class="flex items-center border rounded-md py-1 px-8 text-white lg:text-sm text-xs css-18s1imn" style={{ backgroundColor: '#2FB6E9' }}>Unduh</a>
                                                    </>
                                                  ) : (
                                                    <a href={value?.path} target="_blank" class="flex items-center border rounded-md py-1 px-8 text-white lg:text-sm text-xs css-18s1imn" style={{ backgroundColor: '#2FB6E9' }}>View</a>
                                                  )
                                                }
                                              </div>
                                            </div>
                                          </span>
                                        )
                                        })
                                      }
                                    </div>
                                </div>
                            </div>
                        </div>
                      );
                  })}
                </div>
          </div>
        )
}

export default Detail