import {SETDETAILSPPID, SETMENULISTPPID, RESETDETAILSPPID, RESETMENULISTPPID, SETDETAILBYID, RESETDETAILBYID, SETFILEBYID, RESETFILEBYID} from "./ppidTypes"
import {setDataLoading} from "redux/global/globalActions"
import {responseResult} from "utils/ResponseStatus"
import ApiGet from "api/ApiGet"


export const setMenuListPpid = (payload) => {
    return{
        type: SETMENULISTPPID,
        payload
    }
}

export const resetMenuListPpid = (payload) => {
    return{
        type: RESETMENULISTPPID,
        payload
    }
}

export const setDetailsPpid = (payload) => {
    return {
        type: SETDETAILSPPID,
        payload
    }
}

export const resetDetailsPpid = (payload) => {
    return {
        type: RESETDETAILSPPID,
        payload
    }
}

export const setDetailById = (payload) => {
    return {
        type : SETDETAILBYID,
        payload
    }
}

export const resetDetailById = (payload) => {
    return {
        type : RESETDETAILBYID,
        payload
    }
}

export const setFileById = (payload) => {
    return {
        type : SETFILEBYID,
        payload
    }
}

export const resetFileById = (payload) => {
    return {
        type : RESETFILEBYID,
        payload
    }
}

export const getMenuListPpid = (params) => async (dispatch) => {
    dispatch(setDataLoading(true))
    return ApiGet.actions.PPIDTitleList(params)
        .then(res => {
        console.log("params",params)

            if (responseResult(res)){
                dispatch(setMenuListPpid(responseResult(res)))
            }
            dispatch(setDataLoading(false))
        })
        .catch(err => {
            dispatch(resetMenuListPpid())
            dispatch(setDataLoading(false))
        })
}

export const getDetailsPpid = (params) => async (dispatch) => {
    dispatch(setDataLoading(true))
    return ApiGet.actions.PPIDDetails(params)
    .then(res => {
        if (responseResult(res)){
            dispatch(setDetailsPpid(responseResult(res)))
        }
        dispatch(setDataLoading(false))
        console.log("params",params)
        })
        .catch(err => {
            dispatch(resetDetailsPpid())
            dispatch(setDataLoading(false))
        })

}
export const getDetailById = (params) => async (dispatch) => {
    dispatch(setDataLoading(true))
    console.log("paramsss",params)
    return ApiGet.actions.PPIDDetailById(params)
    .then(res => {
        if (responseResult(res)){
            console.log("res",res)
            dispatch(setDetailById(responseResult(res)))
        }
        dispatch(setDataLoading(false))
        })
        .catch(err => {
            dispatch(resetDetailById())
            dispatch(setDataLoading(false))
        })

}
export const getFileById = (paramsFile) => async (dispatch) => {
    dispatch(setDataLoading(true))
    console.log("paramsssSS",paramsFile)
    return ApiGet.actions.PPIDFiles(paramsFile)
    .then(res => {
        if (responseResult(res)){
            console.log("res",res)
            dispatch(setFileById(responseResult(res)))
        }
        dispatch(setDataLoading(false))
        })
        .catch(err => {
            dispatch(resetFileById())
            dispatch(setDataLoading(false))
        })

}

