import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {getDetailsPpid, getMenuListPpid, getDetailById, getFileById} from "redux/ppid/ppidActions"
import { useSelector, useDispatch } from "react-redux";


function Submenu({ items }) {
  const lang = localStorage.getItem('i18nextLng');

    // console.log("item",items)
    let selector = useSelector((state)=>state.ppid)
  const dispatch = useDispatch()
    const convertToSlug = (str) => {
        return '/' + str
          .toLowerCase()
          .trim()
          .replace(/\s+/g, '-') // Mengganti semua spasi dengan tanda hubung
          .replace(/[^a-z0-9-]/g, ''); // Menghapus karakter non-alphanumeric kecuali tanda hubung
      };

      const handleOnClick = (id) => {
        console.log("idSubmenu",id)
        let params = id? "?id="+id : "" 
        let paramsFile = id? "?id_table_menu="+id : "" 
        dispatch(getDetailById(params))
        dispatch(getFileById(paramsFile))
        } 
        useEffect(()=> {
            dispatch(getDetailsPpid())
            dispatch(getMenuListPpid())
            dispatch(getDetailById())
            dispatch(getFileById())
          },[dispatch])
    return (
            <>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                {items.map((item) => (
                    <Disclosure key={item.id}>
                        {({ open }) => (
                            <Disclosure.Button className="flex w-full justify-between rounded-lg  px-4 py-2 text-left text-sm text-[15px] p-[14px] text-gray-1500 hover:bg-[#F0F2F2] focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 ml-10">
                            <div  onClick={() => handleOnClick(item.id)}>
                                <div className="my-1 text-[17px] ">{lang === "en" ? item?.menu_en :item?.menu}</div>
                            </div>
                        </Disclosure.Button>
                        )}
                    </Disclosure>
                ))}
                </Disclosure.Panel>
            </>
    );
  }

export default Submenu