import React from "react";
import { MapContainer, TileLayer, useMap, Marker, Popup, Tooltip, } from 'react-leaflet'
import "leaflet/dist/leaflet.css"

const Leaflet = ({location}) => {
    const position = [51.505, -0.09]
    
    return (
        <>
          <MapContainer className="h-[40vh] w-[90vh]" center={position} zoom={13} scrollWheelZoom={false}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position} >
                <Popup  >
                  <div className="max-w-[250px] rounded overflow-hidden shadow-lg min-w-[200px]">
                    <img className="w-full" src={`popup.imgUrl`} alt="Foto ya" />
                    <div className="px-6 py-4">
                      <div className="font-bold text-xl mb-2">{`i18n.language === 'id' ? popup.judul : popup.title`}</div>
                      {/* <p className="text-gray-700 text-base ori" dangerouslySetInnerHTML={{ __html: i18n.language === 'id' ? popup.deskripsi : popup.description }}>
                      </p> */}
                    </div>
                    <div className="flex justify-center items-center mb-4 ">
                      <a href={"https://www.google.com/maps/place/" + position} target="_blank" rel="noreferrer" className="mx-4 py-2 w-full rounded-lg bg-primary text-center"><span className='text-white'>Open</span></a>
                      <button onClick={`closePopup`} type="button" className="mx-4 py-2 bg-red-500 w-full text-white rounded-lg">Close</button>
                    </div>
                  </div>
                </Popup>
                {/* <Tooltip>{tooltip}</Tooltip> */}
              </Marker>
          </MapContainer>,
        </>
    )
}

export default Leaflet;