import i18next from "i18next";
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import EN from './en.json';
import ID from './id.json';

// the translations
const resources = {
    en: {
        translation: EN
    },
    id: {
        translation: ID
    }
};

const formatApiData = (data) => {
    const translations = { in: {}, en: {} };
console.log("isi Data ",data)
  data.forEach((item) => {
    // Title
    translations.in[`titles_${item.id}`] = item.title;
    translations.en[`title_${item.id}`] = item.title_en;
    console.log(translations.in)
    // Description
    translations.in[`description_${item.id}`] = item.description;
    translations.en[`description_${item.id}`] = item.description_en;
  });

  debugger;

  return translations;
};

let lng = 'id';
const storageLanguage = localStorage.getItem('i18nextLng');
if (storageLanguage) {
    lng = storageLanguage;
}

i18next
    .use(Backend)
    .use(LanguageDetector) // detect user language, learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources,
        lng: lng, // if you're using a language detector, do not define the lng option
        fallbackLng: "id",
        debug: true,
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
        // backend: {
        //     loadPath: 'http://localhost:4001/landing/layanan/jenis-layanan?status=1', // Ganti dengan endpoint API Anda
        //     parse: (data) => {
        //       const parsedData = JSON.parse(data); // Parse JSON
        //       return formatApiData(parsedData);   // Format data API ke struktur i18next
        //     },
        // },
    });

export default i18next;
