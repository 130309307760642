import React, { useState } from "react";
import Form from "components/reuseable/FormInput/Form";
import Input from "components/reuseable/FormInput/Input";
import TextArea from "components/reuseable/FormInput/TextArea";
import useRedux from "redux/useRedux";
import footerMail from 'assets/images/icons/footer-mail.svg';
import footerLoc from 'assets/images/icons/footer-loc.svg';
import ApiPost from "api/ApiPost";
import { SwalPreConfirm, SwalSuccessThen } from "components/reuseable/Alert/SweetAlert";
import { t } from "i18next";


const KontakKami = () => {
    const {
        global: {
            footer
        }
    } = useRedux();
    const [state, setState] = useState({
        nama_depan: '',
        nama_belakang: '',
        email: '',
        subject: '',
        message: '',
    })

    const onChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }
    const onSubmit = (e) => {
        e.preventDefault();
        // console.log('state', state)
        SwalPreConfirm({
            preConfirm: () => {
                return ApiPost.actions.saveContact(state)
            },
            callback: () => {
                SwalSuccessThen({
                    title: 'Success',
                    text: 'Tim kami akan menghubungi Anda kembali dalam waktu 24 jam',
                    callback: () => setState({
                        nama_depan: '',
                        nama_belakang: '',
                        email: '',
                        subject: '',
                        message: '',
                    })
                })
            },
        })
    }

    return (
        <>
            <div className="mt-[110px]">
                <div className={`bg-profil flex flex-col justify-center items-center`}>
                    <div className="text-white text-[40px] font-semibold uppercase">
                        {t('kontak')}
                    </div>
                </div>
                <div className="section">
                    <div className="my-10 text-[15px]">
                        {t('kontak_kami_deskripsi01')}
                    </div>
                    <Form className="flex border-2 rounded-lg" onSubmit={onSubmit} >
                        <div className="flex flex-row w-full">
                            <div className="md:basis-3/4 flex flex-col w-full pl-2 pr-6 md:pr-0 md:p-6">
                                <div className="flex flex-col md:flex-row">
                                    <Input
                                        classNameDiv="flex flex-col m-2 w-full"
                                        label={t('nama_depan')}
                                        classNameLabel="mb-2"
                                        name="nama_depan"
                                        value={state?.nama_depan}
                                        type="text"
                                        placeholder="Ketik nama depan mu disini..."
                                        onChange={onChange}
                                        required
                                    />
                                    <Input
                                        classNameDiv="flex flex-col m-2 w-full"
                                        label={t('nama_belakang')}
                                        classNameLabel="mb-2"
                                        name="nama_belakang"
                                        value={state?.nama_belakang}
                                        type="text"
                                        placeholder="Ketik nama belakang mu disini..."
                                        onChange={onChange}
                                        required
                                    />
                                </div>

                                <Input
                                    classNameDiv="flex flex-col m-2 w-full md:w-[98.3%]"
                                    label="E-Mail"
                                    classNameLabel="mb-2"
                                    name="email"
                                    value={state?.email}
                                    type="email"
                                    placeholder="Masukan email aktif kamu disini ..."
                                    onChange={onChange}
                                    required
                                />
                                <Input
                                    classNameDiv="flex flex-col m-2 w-full md:w-[98.3%]"
                                    label={t('subjek')}
                                    classNameLabel="mb-2"
                                    name="subject"
                                    value={state?.subject}
                                    type="text"
                                    placeholder="Tulis subjek pesan ini..."
                                    onChange={onChange}
                                    required
                                />
                                <TextArea
                                    classNameDiv="flex flex-col m-2 w-full md:w-[98.3%]"
                                    label={t('pesan')}
                                    classNameLabel="mb-2"
                                    name="message"
                                    value={state?.message}
                                    type="tel"
                                    placeholder="Tulis pesanmu disini.."
                                    onChange={onChange}
                                    required
                                />
                                <button type="submit" className="rounded-lg bg-[#025AA1] text-white py-2 m-2 w-full md:w-[98.3%]">{t('kirim_pesan')}</button>
                            </div>
                            <div className="hidden md:basis-1/4 md:flex flex-col bg-[#0275BC] text-white rounded-r-lg md:p-6">
                                <div className="text-[15px] text-justify">
                                    <div className="font-bold">
                                        {t('informasi_kontak')}
                                    </div>
                                    <div>
                                        {t('kontak_kami_deskripsi02')}
                                    </div>
                                </div>
                                <div className="text-[15px] text-justify mt-4">
                                    <div className="font-bold">
                                        PT. Pelindo Solusi Logistik
                                    </div>
                                    <div>
                                        {footer?.address && (
                                            <>
                                                <div className="flex flex-row">
                                                    <div className="mr-2">
                                                        <img src={footerLoc} alt="loc" style={{ minWidth: '30px' }} />
                                                    </div>
                                                    <div className="text-[15px]">{footer?.address}</div>
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    {footer?.email && (
                                        <div className="flex flex-row items-center">
                                            <div className="mr-2">
                                                <img src={footerMail} alt="mail" style={{ minWidth: '30px' }} />
                                            </div>
                                            <div className="text-[15px]">{footer?.email}</div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    )
}
export default KontakKami