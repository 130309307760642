import React, { useEffect, useState } from "react";
import YoutubeEmbed from "components/reuseable/EmbedVideo/YoutubeEmbed";
import ApiGet from "api/ApiGet";
import { responseResult } from "utils/ResponseStatus";
import FroalaEditorView from "components/reuseable/FormInput/FroalaEditorTextArea";
import Akhlak from "../Profil/Akhlak";
import batik from 'assets/images/batik.jpg';

const TentangKamiHome = () => {
    const [state, setState] = useState(null);
    const lang = localStorage.getItem('i18nextLng');


    useEffect(() => {
        ApiGet.actions.TentangKami()
            .then((response) => {
                if (responseResult(response)) {
                    setState(responseResult(response))
                }
            })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="flex flex-wrap md:flex-nowrap flex-row justify-between">
                <div className="w-full flex flex-col text-justify mb-4 md:mr-4">
                    {state?.description && (
                        <FroalaEditorView
                            model={lang === "en" ? state?.description_en : state?.description}
                        />
                    )}
                </div>
                <div className="w-full rounded-lg bg-white md:ml-4">
                    {
                        state?.video && <YoutubeEmbed embedId={state?.video} />
                    }
                </div>
            </div>
            <div className="flex flex-wrap md:flex-nowrap flex-row mt-10">
                <div className="flex flex-col w-full mb-4 mr-0 md:mr-[57px]">
                    <div className="visi-misi">{lang === "en" ? "Vision" : "Visi"}</div>
                    <div className="text-justify">{lang === "en" ? state?.visi_en : state?.visi}</div>
                </div>
                <div className="flex flex-col w-full ml-0 md:ml-[57px]">
                    <div className="visi-misi">{lang === "en" ? "Mission" : "Misi"}</div>
                    <div className="text-justify">{lang === "en" ? state?.misi_en : state?.misi}</div>
                </div>
            </div>

            <Akhlak />

            {/* <div className="flex flex-col">
                <div className="flex flex-col">
                    <div className="flex flex-row items-center justify-center text-7xl md:text-9xl my-6">
                        <span className="text-akh">AKH</span>
                        <span className="text-lak">LAK</span>
                    </div>
                    <div className="flex flex-wrap flex-row justify-between">
                        <div className="flex flex-col m-5 w-[350px]">
                            <div className="flex flex-row items-center h-[60px] mb-2">
                                <div className="mr-4">
                                    <img src={amanah} alt="icon" width={60} height={60} />
                                </div>
                                <div className="text-akh text-[24px]">Amanah</div>
                            </div>
                            <div className="text-[#5F6B72] text-[20px]">Kami memegang Teguh Kepercayaan</div>
                        </div>
                        <div className="flex flex-col m-5 w-[350px]">
                            <div className="flex flex-row items-center h-[60px] mb-2">
                                <div className="mr-4">
                                    <img src={kompeten} alt="icon" width={60} height={60} />
                                </div>
                                <div className="text-akh text-[24px]">Kompeten</div>
                            </div>
                            <div className="text-[#5F6B72] text-[20px]">Kami terus belajar dan mengembangkan kapabiilitas</div>
                        </div>
                        <div className="flex flex-col m-5 w-[350px]">
                            <div className="flex flex-row items-center h-[60px] mb-2">
                                <div className="mr-4">
                                    <img src={harmonis} alt="icon" width={60} height={60} />
                                </div>
                                <div className="text-akh text-[24px]">Harmonis</div>
                            </div>
                            <div className="text-[#5F6B72] text-[20px]">Kami saling peduli dan menghargai perbedaan</div>
                        </div>
                        <div className="flex flex-col m-5 w-[350px]">
                            <div className="flex flex-row items-center h-[60px] mb-2">
                                <div className="mr-4">
                                    <img src={loyal} alt="icon" width={60} height={60} />
                                </div>
                                <div className="text-lak text-[24px]">Loyal</div>
                            </div>
                            <div className="text-[#5F6B72] text-[20px]">Kami berdedikasi dan mengutamakan kepetingan bangsa negara</div>
                        </div>
                        <div className="flex flex-col m-5 w-[350px]">
                            <div className="flex flex-row items-center h-[60px] mb-2">
                                <div className="mr-4">
                                    <img src={adaptif} alt="icon" width={60} height={60} />
                                </div>
                                <div className="text-lak text-[24px]">Adaptif</div>
                            </div>
                            <div className="text-[#5F6B72] text-[20px]">Kami berinovasi dan antusias dalam menggerakan atau menghadapi perubahan</div>
                        </div>
                        <div className="flex flex-col m-5 w-[350px]">
                            <div className="flex flex-row items-center h-[60px] mb-2">
                                <div className="mr-4">
                                    <img src={kolaboratif} alt="icon" width={60} height={60} />
                                </div>
                                <div className="text-lak text-[24px]">Kolaboratif</div>
                            </div>
                            <div className="text-[#5F6B72] text-[20px]">Kami membangun kerja sama yang strategis</div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className="absolute bottom-[170px] right-0">
                <img src={batik} alt={'batik'} style={{
                    width: '300px',
                    height: '300px'
                }} />
            </div> */}
        </>
    )
}

export default TentangKamiHome;