import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import icWhatsapp from "assets/images/whatsapp.png"
import icArrowUp from "assets/images/icons/arrow-up.svg"

const Whatsapp = () => {
    let navigate = useNavigate()
    const [isHover, setIsHover] = useState(false)
    const [hoverPosition, setHoverPosition] = useState("")
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
            setHoverPosition("scrolled")
        }
        else if (scrolled <= 300) {
            setVisible(false)
            setHoverPosition("")
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible);
        return () => {
            window.removeEventListener('scroll', toggleVisible)
        }
    }, [])

    return (
        <>
            {
                isHover && (
                    <div className={`whatsapp-text ${hoverPosition}`}>
                        <b>Halo!</b> Silahkan Kontak Kami melalui WhatsApp?
                        <br />
                        Klik disini
                    </div>
                )
            }

            <div className={`whatsapp ${hoverPosition}`}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={() => {
                    window.open('https://api.whatsapp.com/send?phone=6281285522162&#038;text=Hi%PSL%2C%20saya%20ingin%20bertanya%20tentang%20servis%20logistik', "_self")
                }}
            >
                <img src={icWhatsapp} alt="whatsapp" />
            </div>

            {
                visible && (
                    <div className="back-to-top bg-white rounded-lg px-4 py-2 flex flex-row" onClick={scrollToTop}>
                        <div className="text-primary text-[15px] mr-[10px]">Back To Top</div>
                        <img src={icArrowUp} alt="arrow-up" />
                    </div>
                )
            }

        </>
    )
}

export default Whatsapp