import React, { useEffect, useState } from "react";
import { useOutletContext, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";

const EPpid = () => {

  // useEffect(() => {
  //   axios
  //       .get("http://localhost:4001/landing/ppid/title-list", {})
  //       .then(({ data }) => {
  //           setTitleList(data.responseResult)
  //       })
  //       .catch((err) => console.log(err));
  // }, []);


  return (
      <>
        <div className="flex-1 p-6 md:grid-cols-9 md:block">
              <div className="flex-1 flex-col sm:my-5 mx-2 md:mx-20 text-justify">
                  
                            
              </div>
        </div>
      </>
  )
}
export default EPpid;