import React, { useEffect, useState } from "react";
// import amanah from 'assets/images/icons/amanah.svg';
// import kompeten from 'assets/images/icons/kompeten.svg';
// import harmonis from 'assets/images/icons/harmonis.svg';
// import loyal from 'assets/images/icons/loyal.svg';
// import adaptif from 'assets/images/icons/adaptif.svg';
// import kolaboratif from 'assets/images/icons/kolaboratif.svg';
import ApiGet from "api/ApiGet";
import { responseResult } from "utils/ResponseStatus";
import { uAPI } from "api/config";

const Akhlak = () => {
    const [state, setState] = useState(null);
    const lang = localStorage.getItem('i18nextLng');


    useEffect(() => {
        ApiGet.actions.CoreValue()
            .then((response) => {
                if (responseResult(response)) {
                    setState(responseResult(response))
                }
            })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="flex-none flex-col flex-grow-0 order-0">
                <div className="flex flex-col">
                    <div className="flex flex-row items-center justify-center text-7xl md:text-9xl my-[40px] p-5">
                        {/* <span className="text-akh">AKH</span>
                        <span className="text-lak">LAK</span> */}
                        <img src={uAPI + state?.pic} alt={state?.alt_pic} style={{width: '300px',height: '80px'}} />
                    </div>
                    <div className="flex flex-wrap flex-row justify-start md:justify-between">
                        {
                            state?.detail?.length > 0 && state?.detail?.map((val, ind) => {
                                return (
                                    <div key={ind} className="flex flex-col m-5 w-[350px]">
                                        <div className="flex flex-row items-center h-[60px] mb-2">
                                            {
                                                val?.icon && <div className="mr-4">
                                                    <img src={uAPI + val?.icon} alt={val?.alt_icon} width={40} height={40} />
                                                </div>
                                            }
                                            <div className={`text-[18px] font-bold`} style={{
                                                color: val?.color
                                            }}>{val?.value_name}</div>
                                        </div>
                                        <div className="text-[#5F6B72] text-[14px] text-justify">{lang === "en" ? val?.description_en : val?.description}</div>
                                    </div>
                                )
                            })
                        }
                        {/* <div className="flex flex-col m-5 w-[350px]">
                            <div className="flex flex-row items-center h-[60px] mb-2">
                                <div className="mr-4">
                                    <img src={amanah} alt="icon" width={40} height={40} />
                                </div>
                                <div className="text-akh text-[18px] font-bold">Amanah</div>
                            </div>
                            <div className="text-[#5F6B72] text-[14px] text-justify">Kami memegang Teguh Kepercayaan</div>
                        </div>
                        <div className="flex flex-col m-5 w-[350px]">
                            <div className="flex flex-row items-center h-[60px] mb-2">
                                <div className="mr-4">
                                    <img src={kompeten} alt="icon" width={40} height={40} />
                                </div>
                                <div className="text-akh text-[18px] font-bold">Kompeten</div>
                            </div>
                            <div className="text-[#5F6B72] text-[14px] text-justify">Kami terus belajar dan mengembangkan kapabiilitas</div>
                        </div>
                        <div className="flex flex-col m-5 w-[350px]">
                            <div className="flex flex-row items-center h-[60px] mb-2">
                                <div className="mr-4">
                                    <img src={harmonis} alt="icon" width={40} height={40} />
                                </div>
                                <div className="text-akh text-[18px] font-bold">Harmonis</div>
                            </div>
                            <div className="text-[#5F6B72] text-[14px] text-justify">Kami saling peduli dan menghargai perbedaan</div>
                        </div>
                        <div className="flex flex-col m-5 w-[350px]">
                            <div className="flex flex-row items-center h-[60px] mb-2">
                                <div className="mr-4">
                                    <img src={loyal} alt="icon" width={40} height={40} />
                                </div>
                                <div className="text-lak text-[18px] font-bold">Loyal</div>
                            </div>
                            <div className="text-[#5F6B72] text-[14px] text-justify">Kami berdedikasi dan mengutamakan kepetingan bangsa negara</div>
                        </div>
                        <div className="flex flex-col m-5 w-[350px]">
                            <div className="flex flex-row items-center h-[60px] mb-2">
                                <div className="mr-4">
                                    <img src={adaptif} alt="icon" width={40} height={40} />
                                </div>
                                <div className="text-lak text-[18px] font-bold">Adaptif</div>
                            </div>
                            <div className="text-[#5F6B72] text-[14px] text-justify">Kami berinovasi dan antusias dalam menggerakan atau menghadapi perubahan</div>
                        </div>
                        <div className="flex flex-col m-5 w-[350px]">
                            <div className="flex flex-row items-center h-[60px] mb-2">
                                <div className="mr-4">
                                    <img src={kolaboratif} alt="icon" width={40} height={40} />
                                </div>
                                <div className="text-lak text-[18px] font-bold">Kolaboratif</div>
                            </div>
                            <div className="text-[#5F6B72] text-[14px] text-justify">Kami membangun kerja sama yang strategis</div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}
export default Akhlak;