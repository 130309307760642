import React from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import PropTypes from "prop-types";
import MapsComponent from "./MapsComponent";

const GoogleMaps = (props) => {
    const { location, zoom } = props;

    const rendermap = (status) => {
        console.log('status', status)
        if (status === Status.LOADING) return <h3>{status} ..</h3>;
        if (status === Status.FAILURE) return <h3>{status} ...</h3>;
        return null;
    }

    // console.log('location', location)

    return (
        <>
            <Wrapper apiKey={"AIzaSyBoZygiOj4-NpAqTZVALIPryg0d68yF7as"} render={rendermap}>
            {/* <Wrapper apiKey={"AIzaSyAdfB-1tzijt8NQRVY6SLNft9_JwxWxu1s"} render={rendermap}> */}
                <MapsComponent location={location} zoom={zoom} />
            </Wrapper>
        </>
    )
}
MapsComponent.defaultProps = {
    location: [],
    zoom: 4,
};

MapsComponent.propTypes = {
    location: PropTypes.array.isRequired,
    zoom: PropTypes.number,
};
export default GoogleMaps